import { matchPath } from 'react-router'
import { useRouter } from 'src/hooks/useRouter'

export const Switch = ({ config, fallback = React.Fragment }) => {
  const router = useRouter()

  let _component

  config.forEach((component) => {
    component.paths.forEach((path) => {
      if (matchPath(router.pathname, { path, exact: true })) {
        if (_component === undefined) {
          _component = component.component
        }
      }
    })
  })

  return _component === undefined ? React.createElement(fallback) : _component
}

import { AlertStyles } from './styles'

export const CouponAppliedMessage = ({
  alertColor = 'var(--persianBlue)',
  coupon,
  hasTopMargin,
}) => (
  <AlertStyles.Success alertColor={alertColor} hasTopMargin={hasTopMargin}>
    {coupon?.message ||
      coupon?.content?.subscriptionMessage ||
      `${coupon?.code} has been applied successfully!`}
  </AlertStyles.Success>
)

import { Redirect } from './Redirect'
import { useRouter } from 'src/hooks/useRouter'
import { useUser } from 'src/hooks/useUser'
import { userMustFinishOnboarding } from 'src/utils/userUtils'

export const OnboardingBouncer = ({ allowedRoutes = ['/logout', '/plans'], children }) => {
  const { currentUser, isPtActive } = useUser() || {}
  const { pathname } = useRouter()

  // TODO: add PT quiz route when it is created
  const redirectRoute = isPtActive ? '' : '/onboarding/welcome'

  return userMustFinishOnboarding(currentUser) && !allowedRoutes.includes(pathname)
    ? React.createElement(Redirect, {
        to: redirectRoute,
      })
    : children
}

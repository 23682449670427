import { Styles } from './styles'

export const ObeFooter = () => (
  <React.Fragment>
    <Styles.GreyRule />
    <Styles.Container>
      <Styles.Logo src='/obe-logo-color.png' width={720} height={312} />
      <div style={{ marginTop: 32 }}>
        <span style={{ textTransform: 'none' }}>© obé Fitness</span>
      </div>
    </Styles.Container>
  </React.Fragment>
)

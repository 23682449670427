import { ObeHeader } from './ObeHeader'
import { OnePageHeader } from './OnePageHeader'
import { SiteHeader } from './SiteHeader'
import { Switch } from '../Switch'
import { TemplateHeader } from './TemplateHeader'

export const Header = ({ logo }) =>
  React.createElement(Switch, {
    config: [
      {
        component: <ObeHeader />,
        paths: [
          '/forgot-password',
          '/login',
          '/onboarding/subscription',
          '/partner/:code',
          '/reset-password/:token',
          '/comeback',
          '/users/personal-training-signup',
        ],
      },
      {
        component: <OnePageHeader />,
        paths: [
          '/users/sign_up',
          '/users/sign_up/new',
          '/users/sign_up/levelup',
          '/users/sign_up/new',
          '/obe-preview',
        ],
      },
      {
        component: <TemplateHeader logo={logo} />,
        paths: [
          '/aa',
          '/samsung',
          '/showfields',
          '/student',
          '/uberone',
          '/users/sign_up/athleta/core',
          '/users/sign_up/athleta/icon',
          '/users/sign_up/athleta/iconreward',
          '/users/sign_up/athleta/enthusiast',
          '/users/sign_up/athleta/enthusiastreward',
        ],
      },
      {
        component: null,
        paths: [
          '/affiliate/:code',
          '/onboarding/quiz',
          '/onboarding/quiz-results',
          '/onboarding/guided-experience-results',
          '/workoutparty/:id',
        ],
      },
    ],
    fallback: SiteHeader,
  })

export const Anchor = ({ hash, offset = -64, behavior = 'auto' }) => {
  const el = React.useRef(null)

  React.useEffect(() => {
    if (window.location.hash.replace('#', '') === hash) {
      el.current.scrollIntoView({ behavior })
    }
  }, [])

  return <div ref={el} id={hash} style={{ position: 'relative', top: offset }} />
}

import axios from 'axios'
import debounce from 'lodash.debounce'
import { useAsyncCallback } from 'react-async-hook'
import { usernameAvailability } from 'src/models/profile'

export const useUsernameAvailability = (username, initialUsername) => {
  const [isAvailable, setIsAvailable] = React.useState()

  const asyncUsernameAvailability = useAsyncCallback(async (username, cancelToken) => {
    try {
      const result = await usernameAvailability(username, cancelToken)
      setIsAvailable(result?.data?.available)
    } catch (e) {
      if (!axios.isCancel(e)) setIsAvailable(undefined)
    }
  })

  const debouncedUsernameAvailability = React.useRef(
    debounce(
      (username, cancelToken) => asyncUsernameAvailability.execute(username, cancelToken),
      750
    )
  ).current

  React.useEffect(() => {
    setIsAvailable(undefined)
    const { cancel, token } = axios.CancelToken.source()

    if (initialUsername !== username && username?.length > 2) {
      debouncedUsernameAvailability(username, token)
    }

    return () => {
      cancel()
    }
  }, [debouncedUsernameAvailability, username])

  return { isAvailable, isLoading: asyncUsernameAvailability.loading }
}

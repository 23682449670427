import { getSavedObeClasses } from 'src/models/savedObeClass'
import useSwr, { mutate, useSWRConfig } from 'swr'
import useUser from '../useUser'

export { clearCache, setSavedClasses } from './setters'
export const generateSavedObeClassesUserKey = (userId) =>
  `/api/v3/saved_obe_classes/${userId ? userId : `me/${obe.storage_keys.swr_me_key}`}`
export const loadSavedClasses = () => mutate(generateSavedObeClassesUserKey())

export const useSavedClasses = (userId) => {
  const { isMember } = useUser()
  const USER_KEY = generateSavedObeClassesUserKey(userId)
  const { cache } = useSWRConfig()

  if (!isMember && cache.get(USER_KEY) !== null) cache.set(USER_KEY, null)

  const { data, error } = useSwr(USER_KEY, () => getSavedObeClasses(userId), {
    revalidateOnMount: !cache.has(USER_KEY),
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })

  const isLoadingInitialState = !data && !error
  const defaultResponse = {
    savedVideos: [],
    isLoadingInitialState,
  }

  if (error || !data) {
    return defaultResponse
  }

  const { savedVideos, favoriteVideos } = data

  return {
    favoriteVideos,
    isLoadingInitialState,
    savedVideos,
  }
}

export default useSavedClasses

import { breakpoint } from 'src/styles'
import { Image } from 'src/components/Display/Image'
import { Link } from 'src/components/Link'
import { PromotionBanner } from './PromotionBanner'

export const ObeHeader = ({ label }) => {
  return (
    <React.Fragment>
      <PromotionBanner />
      <Styles.Container>
        <Link action='Returned to Marketing Page' to='/'>
          <Styles.Logo src='/obe-logo-color.png' width={750} height={312} />
        </Link>
        {label && <Styles.Label>{label}</Styles.Label>}
      </Styles.Container>
    </React.Fragment>
  )
}
const Styles = {
  Container: styled.div`
    margin-bottom: 18px;
    margin-top: 24px;

    ${breakpoint.smallAndDown} {
      margin-bottom: 8px;
      margin-top: 16px;
    }
  `,
  Logo: styled(Image)`
    display: block;
    margin: 0 auto;
    width: 70px;
    ${breakpoint.smallAndDown} {
      width: 50px;
    }
  `,
  Label: styled.div`
    font-size: 14px;
    line-height: 1.45;
    margin-top: 4px;
    text-align: center;
    ${breakpoint.smallAndDown} {
      margin-top: 13px;
    }
  `,
}

import { api } from 'src/api'

export const endSession = () => (api.sessionState = null)

export const startSession = async (user) => {
  const response = await api.client.post('/users/sign_in.json', {
    api_version: 'v3',
    segment_anonymous_id: obe.analytics.anonymousId(),
    user,
  })

  api.sessionState = {
    authorization: response.headers.authorization,
    userId: response.data.id,
  }

  return response
}

import { RedirectIf } from '../RedirectIf'
import { ExceptionDispatcher } from 'src/components/App/ExceptionDispatcher'
import { DispatchPageView } from 'src/components/App/DispatchPageView'
import { Global } from '@emotion/react'
import { isIOS } from 'react-device-detect'
import { QuizFooter } from '../Footer/Quiz'
import { QuizProvider } from 'src/providers/Quiz'
import { QuizHeader } from 'src/components/Quiz/QuizHeader'
import { Slide } from 'src/components/Slide'
import { useRouter } from 'src/hooks/useRouter'
import { Loader } from 'src/components/Display/Loader'
import { useQuizQuestions } from 'src/hooks/useQuizQuestions'
import { useUiState } from 'src/hooks/useStores'
import quizGradientBackground from '../../../../public/quiz/quiz-gradient-background.png'

export const QuizLayout = ({ children }) => {
  const { setDownloadAppModal } = useUiState()
  const {
    asPath,
    query: { quizSlug, questionSlug },
    push,
  } = useRouter()
  const { quiz, loading: isLoadingQuiz, error } = useQuizQuestions(quizSlug)

  const [isReversed, setIsReversed] = React.useState(false)

  // Redirect user to home if quiz does not exist or there are no questions
  React.useEffect(() => {
    if (!isLoadingQuiz && (!quiz || quiz?.questions?.length === 0)) {
      push('/home')
    }
  }, [isLoadingQuiz, push, quiz])

  // Set the download app modal to be shown afterwards if it's the welcome onboarding quiz
  React.useEffect(() => {
    if (quizSlug === obe.quizzes.onboarding.slug) {
      setDownloadAppModal({ shouldOpen: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <RedirectIf.IsGuest>
      <Global
        styles={css`
          body {
            background-color: var(--neutrals300) !important;
          }
        `}
      />
      <ExceptionDispatcher>
        <DispatchPageView>
          <Loader isLoading={isLoadingQuiz || error}>
            <QuizProvider quiz={quiz} questionSlug={questionSlug} setIsReversed={setIsReversed}>
              <Styles.Container backgroundImage={quizGradientBackground.src}>
                <QuizHeader transparentBackground={true} />
                <Styles.SlideContainer>
                  <Styles.Slide
                    transitionKey={asPath}
                    direction={!isReversed ? 'left' : 'right'}
                    // Effectively disable animation temporarily
                    timeout={0}
                  >
                    <Styles.Content>{children}</Styles.Content>
                  </Styles.Slide>
                </Styles.SlideContainer>
                <QuizFooter />
              </Styles.Container>
            </QuizProvider>
          </Loader>
        </DispatchPageView>
      </ExceptionDispatcher>
    </RedirectIf.IsGuest>
  )
}

export const getLayout = (page) => <QuizLayout>{page}</QuizLayout>

const Styles = {
  Container: styled.div`
    position: relative;
    min-height: ${isIOS ? 'stretch' : '100vh'};
    width: 100%;
    background-color: var(--neutrals300) !important;
    display: flex;
    flex-direction: column;

    ${({ backgroundImage }) => css`
      background-image: url(${backgroundImage});
      background-size: 100% 100%;
    `}
  `,
  Content: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  SlideContainer: styled.div`
    flex-grow: 1;
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
  `,
  Slide: styled(Slide)`
    height: fit-content;
  `,
}

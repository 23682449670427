import { breakpoint } from 'src/styles'
import { PromotionBanner } from './PromotionBanner'
import { useResponsive } from 'src/hooks/useResponsive'

export const TemplateHeader = ({ logo }) => {
  const { isLargeAndUp } = useResponsive()

  return (
    <React.Fragment>
      <PromotionBanner />
      <Styles.Container>
        <Styles.Logo>
          <Styles.Image src={logo} />
        </Styles.Logo>
        <Styles.Login>
          {isLargeAndUp && 'Already have an account?'} <a href='/login'>log in</a>
        </Styles.Login>
      </Styles.Container>
    </React.Fragment>
  )
}

const Styles = {
  Container: styled.div`
    height: 60px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f8f8f8;
    border-bottom: 1px solid var(--grey);

    ${breakpoint.largeAndUp} {
      height: 80px;
    }
  `,
  Logo: styled.div`
    align-items: center;
  `,
  Image: styled.img`
    height: 28px;

    ${breakpoint.largeAndUp} {
      height: 40px;
    }
  `,
  Login: styled.div`
    position: absolute;
    right: 17px;
    color: #979797;
    font-size: 14px;
    line-height: 16px;
    text-align: right;

    a {
      color: blue;
      font-weight: 600;
      text-transform: uppercase;
    }

    ${breakpoint.largeAndUp} {
      right: 40px;
    }
  `,
}

import { Anchor } from 'src/components/Anchor'
import { breakpoint } from 'src/styles'
import { FullContainer } from 'src/components/Display/Container'
import { getStreak } from 'src/models/user'
import { Home } from 'src/components/Display/Home'
import { Icon } from 'src/components/Icon'
import { ImpressionTracker } from 'src/components/App/ImpressionTracker'
import { Link } from 'src/components/Link'
import { mixin } from 'src/styles'
import { NavBar } from 'src/views/Profile/NavBar'
import { ProfileCard } from 'src/components/ProfileCard'
import { useAsyncCallback } from 'react-async-hook'
import { useMember } from 'src/providers/MemberProvider'
import { useRouter } from 'src/hooks/useRouter'

export const ProfileLayout = ({ children }) => {
  const { asPath } = useRouter()
  const { currentMember, isSelf, currentUser } = useMember()
  const [trackingData, setTrackingData] = React.useState({})
  const isCollectionPath = asPath.includes('collections')
  const isOverviewPage = window?.location?.pathname === `/${currentUser?.profile?.username}`

  const { result: { data: streak } = {}, execute: getStreakNumber } = useAsyncCallback(
    async () => await getStreak(currentMember?.id)
  )

  React.useEffect(() => {
    if (isSelf) getStreakNumber()

    if (isCollectionPath) {
      setTrackingData({
        to: asPath,
        context: 'profile_collections',
        profileOwner: isSelf ? 'self' : 'friend',
        profileOwnerId: currentMember?.id,
      })
    } else {
      setTrackingData({
        userId: currentUser?.id,
        profileOwner: isSelf ? 'self' : 'friend',
        profileOwnerId: currentMember?.id,
      })
    }
  }, [])

  return (
    <React.Fragment>
      <div>
        <ImpressionTracker
          action={
            isCollectionPath
              ? obe.events.navigation.navigated_to_collection
              : obe.events.navigation.navigated_to_profile
          }
          tracking={{ ...trackingData }}
        >
          <Styles.FullContainer>
            <ProfileCard />
          </Styles.FullContainer>
        </ImpressionTracker>
        <Anchor hash='profile-navbar' />
        <NavBar />
        <Styles.ClassCountContainer hasMarginBottom={!isSelf || !isOverviewPage}>
          <div style={{ display: 'flex' }}>
            <h3>Total Classes Taken: {currentMember?.membershipActivity?.classesCompleted || 0}</h3>
            <Styles.HomeIconContainer>
              <Styles.Home />
              <Styles.Home isBehind />
            </Styles.HomeIconContainer>
          </div>
          {isSelf && !!streak && (
            <ImpressionTracker
              action={obe.events.user_viewed_streak}
              tracking={{
                userId: currentMember?.id,
                streak,
                userStatus: currentMember?.subscription?.status,
              }}
            >
              <Styles.StreakHeader>
                Streak: {streak} Day{streak !== 1 && 's'}
              </Styles.StreakHeader>
            </ImpressionTracker>
          )}
        </Styles.ClassCountContainer>
        {isSelf && isOverviewPage && (
          <Styles.GuestPassBanner>
            Send a guest pass: Share 1 free month of obé with a friend!
            <Styles.GuestPassLink
              action={obe.events.navigation.navigated_to_guest_pass}
              to='/guest-pass'
              tracking={{ context: 'profile' }}
            >
              <Styles.GuestPassIcon /> SEND GUEST PASS
            </Styles.GuestPassLink>
          </Styles.GuestPassBanner>
        )}
        {children}
      </div>
    </React.Fragment>
  )
}

const Styles = {
  FullContainer: styled(FullContainer)`
    display: flex;
    flex-direction: column;
  `,
  ClassCountContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: var(--lightGrey);
    min-height: 80px;
    padding: 16px;

    h3 {
      line-height: 28px;
      font-size: 24px;
      font-weight: 300;
      letter-spacing: 0.5px;
      margin-bottom: 0px;
    }

    ${({ hasMarginBottom }) =>
      hasMarginBottom &&
      css`
        margin-bottom: 32px;

        ${breakpoint.mediumAndDown} {
          margin-bottom: 20px;
        }
      `}

    ${breakpoint.mediumAndDown} {
      min-height: 60px;
    }
  `,
  HomeIconContainer: styled.div`
    position: relative;
    margin-left: 10px;
    width: 14px;
    height: 16px;
  `,
  Home: styled(Home)`
    position: absolute;
    top: 6px;
    left: 0;
    width: 14px;
    height: 16px;
    transform: scale(1.2);
    z-index: 1;

    ${({ isBehind }) =>
      isBehind &&
      css`
        top: 7px;
        right: -2px;
        z-index: 0;
        fill: var(--yellow);

        path {
          stroke: none;
        }
      `}
  `,
  StreakHeader: styled.h3`
    margin-top: 5px;
    font-size: 18px !important;
  `,
  GuestPassBanner: styled.div`
    ${mixin.flexCenterBoth}
    width: 100%;
    background-color: var(--yellow);
    margin-bottom: 32px;
    padding: 22px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    font-size: 18px;
    line-height: 1;
    text-align: center;

    span {
      display: contents;
      font-weight: 500;
    }

    ${breakpoint.mediumAndDown} {
      flex-direction: column;
      margin-bottom: 20px;
      font-size: 14px;
      padding: 14px;
    }
  `,
  GuestPassLink: styled(Link)`
    ${mixin.flexCenterBoth}
    text-transform: uppercase;
    text-decoration: underline;
    font-size: 13px;
    letter-spacing: 0.015em;
    margin-left: 8px;
    white-space: nowrap;

    ${breakpoint.mediumAndDown} {
      margin-top: 6px;
    }
  `,
  GuestPassIcon: styled(Icon.OpenEnvelope)`
    width: 15px;
    height: 15px;
    margin-right: 2px;
  `,
}

import { generateSavedObeClassesUserKey } from './index'
import { mutate } from 'swr'

export const setSavedClasses = (props) => {
  mutate(generateSavedObeClassesUserKey(), (data) => ({ ...data, ...props }), false)
}

export const clearCache = () => {
  mutate(generateSavedObeClassesUserKey(), null, false)
}

import { breakpoint, copy } from 'src/styles'

export const RainbowCard = {
  Container: styled.div`
    background: white;
    padding: 1.5rem 1rem 2rem 1rem;
    position: relative;
    width: 100%;
    ::before {
      content: '';
      width: 100%;
      height: 2px;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(
        90deg,
        var(--yellow) 0.7%,
        var(--mediumPink) 29.86%,
        var(--lightBlue) 52.12%,
        var(--lavender) 99.2%
      );
      transform: matrix(-1, 0, 0, 1, 0, 0);
    }
    ${breakpoint.mediumAndUp} {
      padding-bottom: 4rem;
      padding-top: 2rem;
      padding-left: 90px;
      padding-right: 90px;
    }

    ${breakpoint.smallAndDown} {
      padding-left: 18px;
      padding-right: 18px;
    }
  `,
  Title: styled.h1`
    ${copy.headerXlarge}
    margin-bottom: 24px;
  `,
  BasicContainer: styled.div`
    background: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    position: relative;
    width: 100%;

    ::before {
      content: '';
      width: 100%;
      height: 2px;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(
        90deg,
        var(--yellow) 0.7%,
        var(--mediumPink) 29.86%,
        var(--lightBlue) 52.12%,
        var(--lavender) 99.2%
      );
      transform: matrix(-1, 0, 0, 1, 0, 0);
    }
  `,
  SubTitle: styled.h2`
    font-size: 24px;
    font-weight: 200;
    line-height: 24px;
    text-align: center;
    margin-bottom: 18px;

    ${breakpoint.smallAndDown} {
      font-size: 16px;
    }
  `,
  Dek: styled.p`
    font-size: 16px;
    line-height: 1.45;
    margin-bottom: 24px;
    margin-top: -16px;
  `,
  Description: styled.p`
    font-size: 18px;
    line-height: 1.45;
    margin-bottom: 24px;
    margin-top: -16px;
    ${breakpoint.smallAndDown} {
      font-size: 16px;
    }
  `,
}

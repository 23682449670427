import { api, client } from 'src/api'
import * as Sentry from '@sentry/nextjs'

export const getMember = ({ userId, username }) =>
  client.get('/api/v3/member', {
    params: {
      id: userId,
      username,
    },
  })

export const getMemberFriendsActivity = async (userId) => {
  let response

  if (api.sessionState) {
    try {
      response = await client.get(`/api/v3/members/${userId}/friends_activity`)
    } catch (error) {
      Sentry.captureException(error)
      return {}
    }
  }

  return response
}

export const getWeeklyClassActivity = async (userId) => {
  let response
  let weeklyClassActivity

  if (api.sessionState) {
    try {
      response = await client.get(`/api/v3/members/${userId}/weekly_class_activity`)
    } catch (error) {
      return {}
    }

    if (response?.data) {
      weeklyClassActivity = response?.data
    }

    return weeklyClassActivity
  }

  return {}
}

export const getUpcomingReservations = async (userId) => {
  let response
  let upcomingReservations

  if (api.sessionState) {
    try {
      response = await client.get(`/api/v3/members/${userId}/upcoming_reservations`)
    } catch (error) {
      return {}
    }

    if (response?.data) {
      upcomingReservations = response?.data
    }

    return upcomingReservations
  }

  return {}
}

export const getMemberSchedule = async (startDate, userId) => {
  let response

  if (api.sessionState) {
    try {
      response = await client.get('/api/v3/members/my_schedule', {
        params: {
          ...(startDate && { start_date: startDate }),
          ...(userId && { user_id: startDate }),
        },
      })
    } catch (error) {
      return {}
    }

    return response?.data ?? {}
  }

  return {}
}

import { client } from 'src/api'

export const getProgramCarousels = async () => {
  const { data } = await client.get('/api/v4/carousels/programs')
  return data || []
}

export const getProgramClassCompletionStreamUrl = (userId) =>
  `${process.env.NEXT_PUBLIC_API_URL}/api/v3/obe_program_enrollments/class_completion_stream/?user_id=${userId}`

export const getProgramV2BySlug = async (slug = '') => {
  const { data } = await client.get('/api/v4/programs/by_slug', { params: { slug } })
  return data
}

export const getProgramStatus = (programId) =>
  client.get(`/api/v3/obe_program_enrollments/user_progress?obe_program_id=${programId}`)

export const getProgramEnrollments = async (userId) => {
  return userId
    ? await client.get(`/api/v3/obe_program_enrollments?for_member=${userId}`)
    : await client.get('/api/v3/obe_program_enrollments')
}

export const startProgram = (programId) =>
  client.post('/api/v3/obe_program_enrollments/start_program', {
    obe_program_id: programId,
  })

export const rateProgram = (programId, rating) =>
  client.put('/api/v3/obe_program_enrollments/rate_program', {
    obe_program_id: programId,
    rating,
  })

export const stopProgram = (programId) =>
  client.put('/api/v3/obe_program_enrollments/stop_program', {
    obe_program_id: programId,
  })

export const getWeekDetails = (programId) =>
  client.get(`/api/v4/programs/${programId}/week_details`)

export const getAllProgramSlugs = () => client.get('/api/v4/programs/all_slugs')

export const getObeProgram = async (id) => {
  const { data: program } = await client.get(`/api/v4/programs/${id}`)
  return program
}

export const USER_STATUS = {
  ACTIVE: 'active',
  COMPLETED: 'complete',
  ENROLLED_IN_OTHER_PROGRAM: 'enrolled-in-other-program',
  NOT_STARTED: 'not-started',
  PAUSED: 'paused',
  STOPPED: 'stopped',
}

import { App } from 'src/components/App'
import { BookmarkVideoDialog } from 'src/components/BookmarkVideoDialog'
import { FeatureAnnouncementDialog } from 'src/components/FeatureAnnouncementDialog'
import { GuestPassDialog } from 'src/components/GuestPassDialog'
import { SignUpContextProvider } from 'src/hooks/useSignUpContext/SignUpContextProvider'
import { ToastProvider } from 'src/components/Toast'
import { UpdatedTermsDialog } from 'src/components/UpdatedTermsDialog'
import { useUiState } from 'src/hooks/useStores'
import { useUser } from 'src/hooks/useUser'
import { useRouter } from 'next/router'

export const Layout = ({ children, minHeight = '100vh', hidePartsOnMobileWebView = false }) => {
  const { uiStore, setGuestPassModal } = useUiState()
  const { isInactive, isMember, isLoading } = useUser()
  /* 
     Check if mobile_webview query param exists, if so, we don't show the header and footer 
     in accordance with Google Playstore policies
  */
  const { query } = useRouter()
  const hideNavbarAndFooter =
    Object.prototype.hasOwnProperty.call(query, 'mobile_webview') && hidePartsOnMobileWebView

  if (isLoading) return null

  return (
    <App.ExceptionDispatcher>
      <App.BreakageBouncer isInactive={isInactive}>
        <App.OnboardingBouncer>
          <ToastProvider>
            <div style={{ minHeight }}>
              {!hideNavbarAndFooter && (
                <SignUpContextProvider>
                  <App.Header />
                </SignUpContextProvider>
              )}
              {children}
              <App.NavigationTabs hasHeroBlock={!isMember} />
            </div>
            {!hideNavbarAndFooter && <App.Footer />}
            <BookmarkVideoDialog />
            {isMember && <FeatureAnnouncementDialog />}
            <UpdatedTermsDialog />
            <GuestPassDialog
              isOpen={uiStore?.guestPassModal?.isOpen}
              onClose={() => setGuestPassModal({ isOpen: false })}
            />
          </ToastProvider>
        </App.OnboardingBouncer>
      </App.BreakageBouncer>
    </App.ExceptionDispatcher>
  )
}

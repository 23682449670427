import useSwr from 'swr'
import { getOnboardingQuizBySlug } from 'src/models/quizzes'

export const useQuizQuestions = (quizSlug) => {
  const { data: quiz, error } = useSwr(
    quizSlug === null ? null : `quiz-${quizSlug}`,
    () => getOnboardingQuizBySlug(quizSlug),
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
    }
  )

  const loading = !quiz && !error

  return { quiz, loading, error }
}

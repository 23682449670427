import { BreakageBouncer } from './BreakageBouncer'
import { ContextProvider } from './Context'
import { DispatchPageView } from './DispatchPageView'
import { ExceptionDispatcher } from './ExceptionDispatcher'
import { Footer } from './Footer'
import { Header } from './Header'
import { ImpressionTracker } from './ImpressionTracker'
import { Layout } from './Layouts/Layout'
import { MarketingLayout } from './Layouts/MarketingLayout'
import { NavigationTabs } from './NavigationTabs'
import { OnboardingBouncer } from './OnboardingBouncer'
import { ProfileLayout } from './Layouts/ProfileLayout'
import { QuizLayout } from './Layouts/Quiz'
import { Redirect } from './Redirect'
import { RedirectIf } from './RedirectIf'
import { RequireUser } from './RequireUser'
import { Switch } from './Switch'

export const App = {
  BreakageBouncer,
  ContextProvider,
  DispatchPageView,
  ExceptionDispatcher,
  Footer,
  Header,
  ImpressionTracker,
  Layout,
  MarketingLayout,
  NavigationTabs,
  OnboardingBouncer,
  ProfileLayout,
  QuizLayout,
  Redirect,
  RedirectIf,
  RequireUser,
  Switch,
}

import CircleType from 'circletype'
import { getImageUrl } from 'src/filestack'
import { Icon } from '../Icon'
import { Image } from 'src/components/Display/Image'
import { mixin } from 'src/styles'
import { userInitials } from 'src/utils/userUtils'

export const Avatar = ({
  avatarUrl,
  className,
  firstName,
  foundingMember,
  isLevelUp,
  isMiniAvatar = false,
  lastName,
  styles,
}) => {
  const Styles = { ...DefaultStyles, ...styles }

  React.useEffect(() => {
    if (foundingMember && !isMiniAvatar && document.getElementById('curve-text')) {
      new CircleType(document.getElementById('curve-text'))
        .dir(-1)
        .radius(66)
        .forceHeight(false)
        .forceWidth(false)
    }
  }, [])

  if (avatarUrl) {
    return (
      <Styles.AvatarContainer isMiniAvatar={isMiniAvatar}>
        <Image
          className={className}
          src={getImageUrl(avatarUrl, 'resize=width:256,height:256,fit:crop')}
          circle
          shadow
        />
        {foundingMember && !isMiniAvatar && (
          <Styles.CurvedText id='curve-text'>Founding Member</Styles.CurvedText>
        )}
        {isLevelUp && !isMiniAvatar && <Styles.LevelUpFlag />}
      </Styles.AvatarContainer>
    )
  }

  return (
    <Styles.AvatarContainer isMiniAvatar={isMiniAvatar}>
      <Styles.DefaultAvatar className={className} isMiniAvatar={isMiniAvatar}>
        <div>{userInitials({ firstName, lastName })}</div>
      </Styles.DefaultAvatar>
      {foundingMember && !isMiniAvatar && (
        <Styles.CurvedText id='curve-text' isDefaultAvatar>
          Founding Member
        </Styles.CurvedText>
      )}
      {isLevelUp && !isMiniAvatar && <Styles.LevelUpFlag />}
    </Styles.AvatarContainer>
  )
}

const DefaultStyles = {
  AvatarContainer: styled.div`
    position: relative;
    height: 128px;
    width: 128px;

    ${({ isMiniAvatar }) =>
      isMiniAvatar &&
      css`
        height: 20px;
        width: 20px;
      `}
  `,
  DefaultAvatar: styled.div`
    ${mixin.flexCenterBoth}
    ${mixin.obeGradient}
    border-radius: 50%;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
    font-size: 50px;
    line-height: 1;
    height: 100%;

    ${({ isMiniAvatar }) =>
      isMiniAvatar &&
      css`
        font-size: 9px;
      `}
  `,
  CurvedText: styled.div`
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    text-transform: uppercase;
    font-size: 11px;
    color: white;
    font-weight: 600;
    letter-spacing: -0.5px;

    ${({ isDefaultAvatar }) =>
      isDefaultAvatar &&
      css`
        color: var(--nearBlack);
      `}
  `,
  LevelUpFlag: styled(Icon.LevelUpFlag)`
    position: absolute;
    top: 2px;
    right: 10%;
    width: 17%;
    height: fit-content;
  `,
}

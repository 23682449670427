import { breakpoint, mixin } from 'src/styles'
import { Image } from 'src/components/Display/Image'
import { Link } from 'src/components/Link'
import { MarketingPromoBanner } from './PromotionBanner/MarketingPromoBanner'
import { PromotionBanner } from './PromotionBanner'
import { useResponsive } from 'src/hooks/useResponsive'

export const OnePageHeader = ({ transparentBackground }) => {
  const { isLargeAndUp } = useResponsive()

  return (
    <React.Fragment>
      <MarketingPromoBanner />
      <PromotionBanner />
      <Styles.Container transparentBackground={transparentBackground}>
        <Styles.Left>
          <Styles.Logo action='Returned to Marketing Page' to='/'>
            <Image src='/obe-logo-color.png' width={750} height={312} alt='logo' />
          </Styles.Logo>
        </Styles.Left>
        <Styles.Login>
          {isLargeAndUp && 'Already have an account?'} <Link to='/login'>log in</Link>
        </Styles.Login>
      </Styles.Container>
    </React.Fragment>
  )
}

const Styles = {
  Container: styled.div`
    ${mixin.flexSpaceBetween}
    align-items: flex-end;
    padding: 20px;

    ${({ transparentBackground }) => css`
      background-color: ${transparentBackground ? 'transparent' : '#f8f8f8'};
    `}

    ${breakpoint.largeAndUp} {
      border: 1px solid var(--neutrals400);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03);

      ${({ transparentBackground }) =>
        transparentBackground &&
        css`
          border-bottom: 1px solid var(--neutrals800);
          box-shadow: none;
        `}

      padding: 20px 40px;
    }
  `,
  Left: styled.div`
    padding-left: 33%;
    flex: 1 1 66%;
  `,
  Logo: styled(Link)`
    display: block;
    margin: 0 auto;
    width: 70px;

    ${breakpoint.smallAndDown} {
      width: 50px;
    }
  `,
  Login: styled.div`
    color: #4d4c4f;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    flex: 1 1 33%;

    a {
      color: var(--persianBlue);
      font-weight: 600;
      text-transform: uppercase;
    }
  `,
}

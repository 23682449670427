import { client } from 'src/api'

export const getObeContentBySlug = async (slug) => {
  const { data } = await client.get('/api/v4/content/by_slug', { params: { slug } })
  return data
}

export const getObeContentByType = async (type) => {
  const { data } = await client.get('/api/v4/content/by_type', { params: { type } })
  return data
}

import { getMember } from 'src/models/member'
import { getProgramEnrollments } from 'src/models/program'
import { Loader } from 'src/components/Display/Loader'
import { useRouter } from 'src/hooks/useRouter'
import useSwr, { useSWRConfig } from 'swr'
import { useUser } from 'src/hooks/useUser'

export const MemberContext = React.createContext()

export const MemberProvider = ({ children = Function.prototype }) => {
  const {
    canUseSocialFeatures,
    currentUser,
    isNonbillable,
    isStripeUser,
    username: _username,
  } = useUser()
  const { asPath, query } = useRouter()
  const { cache } = useSWRConfig()

  const usernameQuery = query.username
  const isSelf = usernameQuery === _username || asPath?.includes('profile')

  const {
    data: result,
    error,
    isValidating,
  } = useSwr(
    usernameQuery || 'me',
    (usernameQuery) => (isSelf ? { data: currentUser } : getMember({ username: usernameQuery })),
    {
      revalidateOnMount: isSelf || !cache.has(usernameQuery),
      revalidateOnFocus: false,
    }
  )

  const { data: currentMember } = result || {}

  const { data: programEnrollments } = useSwr(
    currentMember?.id,
    (id) => getProgramEnrollments(isSelf ? undefined : id),
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
    }
  )

  const currentProgramData =
    programEnrollments?.data.find(
      ({ programType, status }) => status === 'active' && programType === 'v2'
    ) || {}
  const isFriend = !!currentMember?.friendDto
  const username = currentMember?.profile?.username

  const contextState = {
    canUseSocialFeatures,
    currentMember,
    currentProgramData,
    currentUser,
    isFriend,
    isNonbillable,
    isSelf,
    isStripeUser,
    username,
  }

  const isLoading = error || isValidating || !currentMember || !currentProgramData

  return (
    <MemberContext.Provider value={contextState}>
      <Loader isLoading={isLoading}>
        {typeof children === 'function'
          ? children({
              isCommunity: !isSelf && !isFriend,
              isFriend,
              isSelf,
            })
          : children}
      </Loader>
    </MemberContext.Provider>
  )
}

export const useMember = () => React.useContext(MemberContext)

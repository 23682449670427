import { acceptFriendRequest, ignoreFriendRequest } from 'src/models/friendRequest'
import { ErrorModal } from './ErrorModal'
import { Form } from 'src/components/Display/Form'
import { mixin } from 'src/styles'
import { useForm } from 'src/hooks/useForm'
import { useComponentContext } from 'src/hooks/useComponentContext'
import { useMember } from 'src/providers/MemberProvider'
import { useUiStore } from 'src/hooks/useStores'
import { useUser, setUser } from 'src/hooks/useUser'

export const IncomingInvitationButtons = ({
  invitation,
  showErrorModal = true,
  refreshFunction = Function.prototype,
  redirectOnAccept,
  className,
}) => {
  const [isIgnored, setIsIgnored] = React.useState(false)
  const [isAdded, setIsAdded] = React.useState(false)
  const [showModal, setShowModal] = React.useState(false)
  const [error, setError] = React.useState()
  const { currentUser } = useUser()
  const { modalType } = useUiStore()
  const { isSelf, isFriend, isCommunity } = useMember() || {}
  const { isFriendSearchModal, isAddFriendModal, isContactsModal } = useComponentContext()

  const getTrackingContext = () => {
    if (isFriendSearchModal || isAddFriendModal || isContactsModal) return modalType?.type
    if (isFriend) return 'friends of friend list'
    if (isCommunity) return 'private profile'
    if (isSelf) return 'own profile'

    return ''
  }

  const { isBusy: acceptIsBusy, submit: submitAccept } = useForm({
    onSubmit: async () => {
      return acceptFriendRequest(invitation.id)
    },
    onComplete: ({ isSuccess, isSocialError, response }) => {
      if (isSuccess) {
        setIsAdded(true)
        invitation.live = false
        setUser({
          ...currentUser,
          membershipActivity: {
            ...currentUser?.membershipActivity,
            newFriendRequests: response?.data?.toMember?.membershipActivity?.newFriendRequests || 0,
            friendCount: response?.data?.toMember?.membershipActivity?.friendCount || 1,
          },
        })
        if (redirectOnAccept) redirectOnAccept()
      } else if (isSocialError) {
        setError(response?.data?.error)
        if (showErrorModal) setShowModal(true)
      }
    },
  })

  const { isBusy: ignoreIsBusy, submit: submitIgnore } = useForm({
    onSubmit: async () => {
      return ignoreFriendRequest(invitation.id)
    },
    onComplete: ({ isSuccess, isSocialError, response }) => {
      if (isSuccess) {
        setIsIgnored(true)
        invitation.live = false
        setUser({
          ...currentUser,
          membershipActivity: {
            ...currentUser?.membershipActivity,
            newFriendRequests: response?.data?.toMember?.membershipActivity?.newFriendRequests || 0,
          },
        })
      } else if (isSocialError) {
        setError(response?.data?.error)
        if (showErrorModal) setShowModal(true)
      }
    },
  })

  return (
    <Styles.ButtonsContainer>
      {React.createElement(Styles.Button, {
        className,
        isBusy: acceptIsBusy || ignoreIsBusy,
        label: 'Accept',
        buttonStyle: 'secondary',
        onClick: submitAccept,
        isDisabled: isAdded || isIgnored || !!error || !invitation?.live,
        tracking: { context: getTrackingContext() },
      })}
      {React.createElement(Styles.Button, {
        className,
        isBusy: ignoreIsBusy || acceptIsBusy,
        label: 'Ignore',
        buttonStyle: isAdded || isIgnored || error ? 'secondary' : 'muted',
        onClick: submitIgnore,
        isDisabled: isAdded || isIgnored || !!error || !invitation?.live,
        action: obe.events.social.clicked_ignore_invitation_button,
        tracking: { context: getTrackingContext() },
      })}
      {showErrorModal && (
        <ErrorModal
          isOpen={showModal}
          setIsOpen={setShowModal}
          error={error}
          refreshFunction={refreshFunction}
        />
      )}
    </Styles.ButtonsContainer>
  )
}

const Styles = {
  ButtonsContainer: styled.div`
    ${mixin.spaceChildrenVertical('5px')}
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 0;
  `,
  Button: styled(Form.Submit)`
    width: 95px;
    padding: 4px 0;

    ${({ isDisabled }) =>
      isDisabled &&
      css`
        :hover {
          background-color: transparent !important;
          border-color: unset !important;
          color: unset !important;
        }
      `}
  `,
}

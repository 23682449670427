export const COLLECTION_TYPES = {
  ALL: {
    ALL_CAPS: 'ALL',
    LOWERCASE: 'all',
    UPPERCASE: 'All',
  },
  FAVORITES: {
    ALL_CAPS: 'FAVORITES',
    LOWERCASE: 'favorites',
    UPPERCASE: 'Favorites',
  },
  FOLLOWED: 'followed',
  PUBLIC: 'public',
  PRIVATE: {
    ALL_CAPS: 'PRIVATE',
    LOWERCASE: 'private',
  },
  SELF_AUTHORED: 'self-authored',
}

import { api } from 'src/api'
import { useInView } from 'react-intersection-observer'

export const ImpressionTracker = ({
  action = '',
  children,
  className,
  tracking = {},
  trackOnlyLoggedIn = true,
  threshold = 0.75,
}) => {
  const [inViewRef, isInView] = useInView({ triggerOnce: true, threshold })
  const isLoggedIn = api.isLoggedIn

  React.useEffect(() => {
    if (isInView) obe.analytics.track(action, tracking)
  }, [isInView])

  if (trackOnlyLoggedIn && !isLoggedIn) return children

  return (
    <Styles.Container ref={inViewRef} className={className}>
      {children}
    </Styles.Container>
  )
}

const Styles = {
  Container: styled.div`
    height: 100%;
  `,
}

ImpressionTracker.propTypes = {
  action: PropTypes.string.isRequired,
}

import { Switch } from '../Switch'
import { Tabs } from './Tabs'
import { useResponsive } from 'src/hooks/useResponsive'
import { useRouter } from 'src/hooks/useRouter'

const smallHeroHeight = 375
const mediumHeroHeight = 476

export const NavigationTabs = ({ hasHeroBlock }) => {
  const { isSmallAndDown } = useResponsive()
  const router = useRouter()

  const options = [
    {
      label: 'Classes',
      path: '/videos',
    },
    {
      label: 'Programs',
      path: '/programs',
    },
    {
      label: 'Collections',
      path: '/obe-collections',
    },
  ]

  const paths = ['/programs', '/videos', '/obe-collections']

  return React.createElement(Switch, {
    config: [
      {
        component: React.createElement(Tabs, {
          pathname: router?.pathname,
          set: options,
          scrollOffset: hasHeroBlock ? (isSmallAndDown ? smallHeroHeight : mediumHeroHeight) : 0,
        }),
        paths,
      },
    ],
  })
}

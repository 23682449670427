import { getContentCollectionBySlug } from './collection'
import { client } from 'src/api'

export const getAssetUrlCollectionBySlug = async (slug) => {
  const assets = {}
  const data = await getContentCollectionBySlug(slug)

  data?.directusFiles?.forEach(({ slug, url }) => (assets[slug] = url))

  return assets
}

export const getAssetBySlug = async (slug) => {
  const { data } = await client.get('/api/v4/obe_assets/by_slug', { params: { slug } })
  return data
}

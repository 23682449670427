import { Redirect } from './Redirect'
import { SignIn } from 'src/views/SignIn/SignIn'
import { storage } from 'src/storage'
import { useUser } from 'src/hooks/useUser'

export const RequireUser = {
  IsMember: ({ children, returnUrl = '' }) => {
    const { isLoading, isGuest, isMember } = useUser()

    if (returnUrl) {
      storage.session.setItem('returnUrl', returnUrl)
    }

    if (isLoading) {
      return null
    }

    if (isGuest) {
      return <SignIn />
    }

    if (!isMember) {
      return <Redirect to='/' />
    }

    return children
  },
}

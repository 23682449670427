import { api, client } from 'src/api'
import * as Sentry from '@sentry/nextjs'

export const bulkUnsaveObeClasses = async (classIds) =>
  client.post('/api/v3/saved_obe_classes/bulk_unsave', {
    obeClassIds: classIds,
  })

export const favoriteObeClass = async (obeClassId) =>
  await client.post('/api/v3/saved_obe_classes/favorite', {
    obeClassId,
  })

export const saveObeClass = async (obeClassId, context) =>
  await client.post('/api/v3/saved_obe_classes/save', {
    obeClassId,
    context,
  })

export const unfavoriteObeClass = async (obeClassId) =>
  await client.post('/api/v3/saved_obe_classes/unfavorite', {
    obeClassId,
  })

export const unsaveObeClass = async (obeClassId) =>
  await client.post('/api/v3/saved_obe_classes/unsave', {
    obeClassId,
  })

export const getSavedObeClasses = async (userId) => {
  let response
  let savedObeClasses

  if (api.sessionState) {
    try {
      response = await client.get('/api/v3/saved_obe_classes', {
        params: {
          for_member: userId || null,
        },
      })
    } catch (error) {
      Sentry.captureException(error)
      return {}
    }
    if (response?.data) {
      savedObeClasses = response?.data
    }
  }

  return savedObeClasses
}

export const StackedPaper = ({ className, children }) => {
  return <Styles.Paper className={className}>{children}</Styles.Paper>
}

const Styles = {
  Paper: styled.div`
    position: relative;
    min-height: 10px;
    min-width: 10px;
    background-color: white;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.12);
    z-index: 10;

    ::before,
    ::after {
      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.12);
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #fff;
    }

    /* Second sheet of paper */
    ::before {
      left: 4px;
      top: 4px;
      z-index: -1;
    }

    /* Third sheet of paper */
    ::after {
      left: 8px;
      top: 8px;
      z-index: -2;
    }
  `,
}

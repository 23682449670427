import { useIsMounted } from 'src/hooks/useIsMounted'

export const useForm = ({ onSubmit, onComplete = Function.prototype }) => {
  const isMounted = useIsMounted()

  const [isBusy, setIsBusy] = React.useState(false)
  const [isError, setIsError] = React.useState(false)
  const [isSuccess, setIsSuccess] = React.useState(false)

  const [errors, setErrors] = React.useState(null)
  const [message, setMessage] = React.useState(null)

  const submit = async (data) => {
    if (!isBusy) {
      try {
        if (isMounted.current) {
          setIsBusy(true)
          setIsError(false)
          setIsSuccess(false)
          setErrors(null)
          setMessage(null)
          const response = await onSubmit(data)
          if (isMounted.current) {
            setIsSuccess(true)
            onComplete({
              isError: false,
              isSuccess: true,
              response,
            })
          }
        }
      } catch (error) {
        if (isMounted.current) {
          const errors = parseErrors(error)
          setIsError(true)
          if (errors) {
            setErrors(errors)
          } else {
            setMessage(error?.response?.data?.error || error?.message)
          }
          onComplete({
            isError: true,
            isSocialError: error?.response?.data?.type === 'social',
            isSuccess: false,
            response: error?.response,
          })
        }
      } finally {
        if (isMounted.current) {
          setIsBusy(false)
        }
      }
    }
  }

  return { submit, isBusy, isError, isSuccess, errors, setErrors, message, setMessage }
}

export const throwFormError = (field, message) => {
  throw {
    response: {
      status: 400,
      data: {
        failedValidations: {
          [field]: [message],
        },
      },
    },
  }
}

const parseErrors = ({ response }) => {
  if (response?.status >= 400 && response?.status <= 499) {
    if (response?.data?.failedValidations || response?.data?.errors) {
      return response.data?.failedValidations || response.data?.errors
    }

    // as a precaution, maintain backwards compatibility if an endpoint returns errors in the old format
    if (
      !response?.data?.error &&
      response?.data?.constructor === Object &&
      Object.entries(response?.data).length !== 0
    ) {
      return response?.data
    }
  }

  return null
}

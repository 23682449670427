import { actions, useToastContext } from 'src/components/Toast'
import {
  addVideoToBookmarkCollection,
  bulkCopyClassesToCollection,
  bulkMoveClassesToCollection,
  createBookmarkCollection,
} from 'src/models/bookmarkCollection'
import { breakpoint } from 'src/styles'
import { COLLECTION_TYPES } from 'src/models/collections'
import { CollectionView } from './CollectionView'
import { Dialog } from 'src/components/Dialog'
import { getAssetUrlCollectionBySlug } from 'src/models/asset'
import { isAllCollection } from 'src/utils/isAllCollection'
import { isFavoritesCollection } from 'src/utils/isFavoritesCollection'
import { Loader } from 'src/components/Display/Loader'
import { Slide } from 'src/components/Slide'
import { setBookmarkCollections, useBookmarkCollections } from 'src/hooks/useBookmarkCollections'
import { setSavedClasses } from 'src/hooks/useSavedClasses'
import { truncateString } from 'src/utils/truncateString'
import { useAsync, useAsyncCallback } from 'react-async-hook'
import { useUiState } from 'src/hooks/useStores'
import { useUser } from 'src/hooks/useUser'

const NewCollectionView = dynamic(() => import('./NewCollectionView'))

const defaultProps = {
  activeCollection: null,
  isAddNewCollection: false,
  isBulkMove: false,
  isOpen: false,
  obeClassIds: null,
  onSuccess: Function.prototype,
  video: null,
}

export const BookmarkVideoDialog = () => {
  const {
    uiStore: {
      bookmarkVideoDialog: {
        activeCollection,
        isAddNewCollection,
        isBulkMove,
        isOpen,
        obeClassIds,
        onSuccess,
        video,
      },
    },
    setBookmarkVideoDialog,
  } = useUiState()
  const { username } = useUser()
  const { bookmarkCollections } = useBookmarkCollections()
  const { toastDispatch } = useToastContext()

  const [slideIndex, setSlideIndex] = React.useState(0)
  const [slideDirection, setDirection] = React.useState('left')
  const [errors, setErrors] = React.useState(null)

  React.useEffect(() => {
    if (!isOpen) {
      setErrors(null)
      setDirection('left')
      setSlideIndex(0)
    }
  }, [isOpen])

  const { result: assets, loading } = useAsync(getAssetUrlCollectionBySlug, [
    'bookmark-collections',
  ])

  const { execute: onCollectionSave, loading: saveLoading } = useAsyncCallback(
    async (collection) => {
      const { data } = await addVideoToBookmarkCollection(video?.id, collection.id)

      obe.analytics.track(obe.events.bookmarks.saved_to_collection, {
        collectionId: collection.id,
        collectionName: collection.name,
        videoId: video?.id,
        isNew: false,
      })

      if (data) {
        const { bookmarkCollections, favoriteVideos, savedVideos } = data
        setBookmarkCollections(bookmarkCollections)
        setSavedClasses({ favoriteVideos, savedVideos })
        onCloseHandler()

        const isFavorites = isFavoritesCollection(collection?.name)

        toastDispatch({
          type: actions.ADD,
          payload: {
            content: `Saved to ${
              isFavorites
                ? COLLECTION_TYPES.FAVORITES.UPPERCASE
                : truncateString(collection?.name, 15)
            }`,
            id: `${collection.id}-${video?.id}-added`,
            to: `/${username || 'profile'}/collections/${
              isFavorites ? COLLECTION_TYPES.FAVORITES.LOWERCASE : collection.id
            }`,
            type: 'link',
          },
        })
      }
    }
  )

  const { execute: onBulkMove, loading: moveLoading } = useAsyncCallback(async (collection) => {
    try {
      setErrors(null)
      const isAll = isAllCollection(activeCollection?.name)
      const { data } = isAll
        ? await bulkCopyClassesToCollection(obeClassIds, collection.id)
        : await bulkMoveClassesToCollection(obeClassIds, activeCollection.id, collection.id)

      if (data) {
        const { bookmarkCollections, favoriteVideos, savedVideos } = data
        setBookmarkCollections(bookmarkCollections)
        setSavedClasses({ favoriteVideos, savedVideos })
        onCloseHandler()
        onSuccess()

        const isFavorites = isFavoritesCollection(collection?.name)

        toastDispatch({
          type: actions.ADD,
          payload: {
            content: `${isAll ? 'Added' : 'Moved'} to ${
              isFavorites
                ? COLLECTION_TYPES.FAVORITES.UPPERCASE
                : truncateString(collection?.name, 15)
            }`,
            id: `${collection.id}-${obeClassIds[0]}-moved`,
            to: `/${username || 'profile'}/collections/${
              isFavorites ? COLLECTION_TYPES.FAVORITES.LOWERCASE : collection.id
            }`,
            type: 'link',
          },
        })
      }
    } catch (error) {
      setErrors(error.response?.data?.error)
    }
  })

  const { execute: onNewCollection, loading: createLoading } = useAsyncCallback(
    async (name, thumbnailUrl, isPrivate, isEmptyCollection) => {
      try {
        setErrors(null)
        const { data: newCollection } = await createBookmarkCollection(
          name,
          thumbnailUrl,
          isPrivate ? COLLECTION_TYPES.PRIVATE.LOWERCASE : 'public'
        )
        obe.analytics.track(obe.events.bookmarks.created_new_collection, {
          collectionId: newCollection.id,
          collectionName: newCollection.name,
        })

        if (!isEmptyCollection) {
          const { data: memberData } = await addVideoToBookmarkCollection(
            video?.id,
            newCollection.id
          )

          obe.analytics.track(obe.events.bookmarks.saved_to_collection, {
            collectionId: newCollection.id,
            collectionName: newCollection.name,
            isNew: true,
            videoId: video?.id,
          })

          if (memberData) {
            const { bookmarkCollections, favoriteVideos, savedVideos } = memberData
            setBookmarkCollections(bookmarkCollections)
            setSavedClasses({ favoriteVideos, savedVideos })
            onCloseHandler()

            toastDispatch({
              type: actions.ADD,
              payload: {
                content: `Saved to ${truncateString(name, 15)}`,
                id: `${name}-${video?.id}-added`,
                to: `/${username || 'profile'}/collections/${newCollection.id}`,
                type: 'link',
              },
            })
          }
        } else if (newCollection) {
          const newBookmarkCollections = bookmarkCollections.concat(newCollection)

          setBookmarkCollections(newBookmarkCollections)
          onCloseHandler()

          toastDispatch({
            type: actions.ADD,
            payload: {
              content: `${truncateString(name, 15)} is added to your Collections`,
              id: `${name}-0-added`,
              to: `/${username || 'profile'}/collections/${newCollection.id}`,
              type: 'link',
            },
          })
        }
      } catch (error) {
        setErrors(error.response?.data?.error)
      }
    }
  )

  const { execute: onNewCollectionBulkMove, loading: createBulkMoveLoading } = useAsyncCallback(
    async (name, thumbnailUrl, isPrivate) => {
      try {
        setErrors(null)
        const isAll = isAllCollection(activeCollection?.name)
        const { data: newCollection } = await createBookmarkCollection(
          name,
          thumbnailUrl,
          isPrivate ? COLLECTION_TYPES.PRIVATE.LOWERCASE : 'public'
        )
        const { data: memberData } = isAll
          ? await bulkCopyClassesToCollection(obeClassIds, newCollection.id)
          : await bulkMoveClassesToCollection(obeClassIds, activeCollection?.id, newCollection.id)

        if (memberData) {
          const { bookmarkCollections, favoriteVideos, savedVideos } = memberData

          setBookmarkCollections(bookmarkCollections)
          setSavedClasses({ favoriteVideos, savedVideos })
          onCloseHandler()
          onSuccess()

          obe.analytics.track(obe.events.bookmarks.created_new_collection, {
            collectionId: newCollection.id,
            collectionName: newCollection.name,
          })

          toastDispatch({
            type: actions.ADD,
            payload: {
              content: `${isAll ? 'Added' : 'Moved'} to ${truncateString(newCollection?.name, 15)}`,
              id: `${newCollection.id}-${obeClassIds[0]}-moved`,
              to: `/${username || 'profile'}/collections/${newCollection.id}`,
              type: 'link',
            },
          })
        }
      } catch (error) {
        setErrors(error.response?.data?.error)
      }
    }
  )

  const onCloseHandler = () => setBookmarkVideoDialog(defaultProps)

  const onNextSlide = () => {
    if (slideDirection === 'right') {
      setDirection('left')
      setTimeout(() => setSlideIndex(slideIndex + 1), 150)
    } else {
      setSlideIndex(slideIndex + 1)
    }
  }

  const onPrevSlide = () => {
    setDirection('right')
    setTimeout(() => setSlideIndex(slideIndex - 1), 150)
  }

  const DialogComponents = [
    <CollectionView
      key={0}
      activeCollection={activeCollection}
      assets={assets}
      disabled={saveLoading || moveLoading}
      isBulkMove={isBulkMove}
      onClose={onCloseHandler}
      onBulkMove={onBulkMove}
      onCollectionSave={onCollectionSave}
      onNextSlide={onNextSlide}
    />,
    <NewCollectionView
      key={1}
      assets={assets}
      errors={errors}
      disabled={createLoading || createBulkMoveLoading}
      isBulkMove={isBulkMove}
      obeClassIds={obeClassIds}
      onClose={onCloseHandler}
      onPrevSlide={onPrevSlide}
      onNewCollection={onNewCollection}
      onNewCollectionBulkMove={onNewCollectionBulkMove}
      video={video}
    />,
  ]

  return (
    <Styles.Dialog isOpen={isOpen} onClose={onCloseHandler}>
      <Loader isLoading={loading}>
        {!isAddNewCollection ? (
          <Slide transitionKey={slideIndex} direction={slideDirection}>
            {DialogComponents[slideIndex]}
          </Slide>
        ) : (
          <NewCollectionView
            key={'new-collection-view'}
            assets={assets}
            errors={errors}
            disabled={createLoading || createBulkMoveLoading}
            onClose={onCloseHandler}
            onNewCollection={onNewCollection}
            isAddNewCollection={isAddNewCollection}
            isBulkMove={isBulkMove}
          />
        )}
      </Loader>
    </Styles.Dialog>
  )
}

const Styles = {
  Dialog: styled(Dialog.Main)`
    background-color: #f8f8f8;
    overflow-x: hidden;
    max-width: 500px;
    max-height: 550px;
    height: 100vh;

    ${breakpoint.smallAndDown} {
      background-color: white;
      box-shadow: 0px 4px 12px rgb(0 0 0 / 14%);
      padding-top: 0;
      margin: 0 auto;
      background-color: #f8f8f8;
      max-width: 100vh;
      max-height: 100%;
      min-height: 100vh;
      margin: 0 -25px;
    }
  `,
}

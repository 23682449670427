import { Container } from 'src/components/Display/Container'
import { Link } from 'src/components/Link'
import { RainbowCard } from 'src/components/RainbowCard'
import { SignInForm } from 'src/components/SignInForm'

export const SignIn = () => (
  <Container>
    <Styles.Container>
      <RainbowCard.Title>Welcome back!</RainbowCard.Title>
      <RainbowCard.Dek>obé looks good on you.</RainbowCard.Dek>
      <SignInForm />
      <Styles.Footer>
        <p>
          Don’t have an account?{' '}
          <Link synthetic={false} to='/users/sign_up'>
            click here
          </Link>
        </p>
        <p>
          <Link to='/forgot-password'>Forgot your password?</Link>
        </p>
      </Styles.Footer>
    </Styles.Container>
  </Container>
)

const Styles = {
  Container: styled(RainbowCard.Container)`
    ::before {
      height: 1px;
      background: var(--lightGrey);
    }
  `,
  Footer: styled.div`
    font-size: 14.4px;
    font-weight: 600;
    color: #9b9b9b;
    margin-top: 24px;
    text-align: center;
    text-transform: uppercase;

    a {
      color: var(--persianBlue);
    }
  `,
}

import { SuccessMessage, ErrorMessage, CouponAppliedMessage } from '.'

export const SubscriptionAlerts = ({ coupon, couponError, error, promotion }) => {
  return (
    <React.Fragment>
      {promotion?.content?.subscriptionMessage && (
        <SuccessMessage
          alertColor='var(--green)'
          message={promotion?.content?.subscriptionMessage}
        />
      )}
      {coupon && <CouponAppliedMessage alertColor='var(--green)' coupon={coupon} />}
      {(error || couponError) && <ErrorMessage error={couponError || error} />}
    </React.Fragment>
  )
}

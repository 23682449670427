import { cancelFriendRequest, sendFriendRequest } from 'src/models/friendRequest'
import { ErrorModal } from './ErrorModal'
import { Form } from 'src/components/Display/Form'
import { useForm } from 'src/hooks/useForm'
import { useComponentContext } from 'src/hooks/useComponentContext'
import { useMember } from 'src/providers/MemberProvider'
import { useUiStore } from 'src/hooks/useStores'

export const AddFriendButton = ({
  className,
  existingFriendRequestId,
  isAlreadyAdded = false,
  refreshFunction,
  showErrorModal = true,
  userId,
}) => {
  const [isAdded, setIsAdded] = React.useState(isAlreadyAdded)
  const [friendRequestId, setFriendRequestId] = React.useState(existingFriendRequestId)
  const [showModal, setShowModal] = React.useState(false)
  const [error, setError] = React.useState()
  const { modalType } = useUiStore()
  const { isFriend, isCommunity, isSelf } = useMember() || {}
  const { isFriendSearchModal, isAddFriendModal, isVodAttendeeModal } = useComponentContext()

  const getTrackingContext = () => {
    if (isFriendSearchModal || isAddFriendModal || isVodAttendeeModal) return modalType?.type
    if (isFriend) return 'friends of friend list'
    if (isCommunity) return 'private profile'
    if (isSelf) return 'own profile'

    return ''
  }

  const { isBusy, submit } = useForm({
    onSubmit: async () => {
      if (isAdded) {
        return cancelFriendRequest(friendRequestId)
      } else {
        return sendFriendRequest(userId, getTrackingContext())
      }
    },
    onComplete: ({ isSuccess, isSocialError, response }) => {
      if (isSuccess) {
        if (isAdded) {
          setFriendRequestId(undefined)
          setIsAdded(false)
        } else {
          setFriendRequestId(response?.data?.id)
          setIsAdded(true)
        }
      } else if (isSocialError) {
        setError(response?.data?.error)
        if (showErrorModal) setShowModal(true)
      }
    },
  })

  const button = React.createElement(Styles.Button, {
    className,
    isBusy,
    label: isAdded ? 'Cancel' : 'Add',
    buttonStyle: isAdded ? 'muted' : 'primary',
    onClick: () => {
      submit()
    },
    isDisabled: !!error,
    action: isAdded
      ? obe.events.social.clicked_cancel_invitation_button
      : obe.events.social.clicked_add_friend_button,
    tracking: { context: getTrackingContext() },
  })

  return (
    <React.Fragment>
      {button}
      {showErrorModal && (
        <ErrorModal
          isOpen={showModal}
          setIsOpen={setShowModal}
          error={error}
          refreshFunction={refreshFunction}
        />
      )}
    </React.Fragment>
  )
}

const Styles = {
  Button: styled(Form.Submit)`
    padding: 4px 16px;
    width: 140px;
    height: fit-content;

    ${({ isDisabled }) =>
      isDisabled &&
      css`
        :hover {
          background-color: transparent !important;
          border-color: unset !important;
          color: unset !important;
        }
      `}
  `,
}

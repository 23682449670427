import { breakpoint, button, mixin } from 'src/styles'
import { Dialog } from 'src/components/Dialog'
import { Dismiss } from 'src/components/Display/Dismiss'
import { ErrorModal } from './ErrorModal'
import { Flex } from 'src/components/Display/Flex'
import { Form } from 'src/components/Display/Form'
import { getMemberDisplayName } from 'src/utils/formattingUtils'
import { PageHeader } from 'src/components/PageHeader'
import { removeFriend } from 'src/models/friend'
import { RichText } from 'src/components/Display/RichText'
import { useForm } from 'src/hooks/useForm'
import { useMember } from 'src/providers/MemberProvider'
import { UserAction } from 'src/components/UserAction'

export const UnfriendModal = React.memo(
  ({ isOpen, setIsOpen, refreshFunction, showErrorModal = true }) => {
    const { currentMember } = useMember()
    const [unfriended, setUnfriended] = React.useState(false)
    const [showModal, setShowModal] = React.useState(false)
    const [error, setError] = React.useState()

    const { isBusy, isError, isSuccess, message, submit } = useForm({
      onSubmit: async () => {
        return removeFriend(currentMember?.friendDto?.id)
      },
      onComplete: ({ isSuccess, isSocialError, response }) => {
        if (isSuccess) {
          setIsOpen(false)
          setUnfriended(true)
        } else if (isSocialError) {
          setError(response?.data?.error)
          if (showErrorModal) setShowModal(true)
        }
      },
    })

    return (
      <React.Fragment>
        <Styles.Dialog
          isOpen={isOpen}
          onClosed={() => (unfriended && refreshFunction ? refreshFunction() : undefined)}
        >
          <Styles.Dismiss onClick={() => setIsOpen(false)} isDisabled={isBusy}>
            <Dismiss />
          </Styles.Dismiss>
          <Styles.Container>
            <Styles.PageHeader title='Unfriend' showBorder={false} />
            <Styles.Body center centraBook>
              <h2>
                Are you sure you want to remove
                <br />
                {getMemberDisplayName(currentMember, { useFullNameWithInitial: true })} as your
                friend?
              </h2>
            </Styles.Body>
            <Flex.Cell largeAndUp={{ width: '80%' }} style={{ margin: '0 auto' }}>
              <Styles.Form isBusy={isBusy}>
                {React.createElement(Form.Notification, {
                  isError,
                  isSuccess,
                  message,
                })}
                <Flex.Row gutter={16}>
                  <Flex.Cell width='50%'>
                    <Styles.Button onClick={() => setIsOpen(false)} isDisabled={isBusy}>
                      Cancel
                    </Styles.Button>
                  </Flex.Cell>
                  <Flex.Cell width='50%'>
                    {React.createElement(Styles.FormSubmit, {
                      isBusy,
                      label: 'Confirm',
                      onClick: submit,
                      buttonStyle: 'secondary',
                      isDisabled: !!error,
                      action: obe.events.social.unfriended_user,
                    })}
                  </Flex.Cell>
                </Flex.Row>
              </Styles.Form>
            </Flex.Cell>
          </Styles.Container>
        </Styles.Dialog>
        {showErrorModal && (
          <ErrorModal
            isOpen={showModal}
            setIsOpen={setShowModal}
            error={error}
            refreshFunction={refreshFunction}
          />
        )}
      </React.Fragment>
    )
  }
)

const Styles = {
  Dismiss: styled(UserAction)`
    display: flex;
    justify-content: flex-end;
    height: 16px;
    padding: 1rem 1rem 0 0;
    margin-bottom: 2rem;

    svg {
      display: block;
    }
  `,
  Container: styled.div`
    padding: 0 3rem 3rem;

    ${breakpoint.mediumAndDown} {
      padding: 0 2rem 3rem;
    }
  `,
  PageHeader: styled(PageHeader)`
    justify-content: center;
    margin-top: 0 !important;
  `,
  Body: styled(RichText.Medium)`
    margin-top: 2.5rem;

    h2 {
      line-height: 32px;
    }

    ${breakpoint.mediumAndDown} {
      margin-top: 0;

      h2 {
        font-size: 18px;
        line-height: 25px;
        margin-top: 0;
      }
    }
  `,
  Form: styled(Form.Form)`
    margin-top: 2.5rem;

    ${breakpoint.mediumAndDown} {
      margin-top: 1.5rem;
    }
  `,
  Button: styled(UserAction)`
    ${button.muted}
    ${mixin.flexCenterBoth}
    height: 100%;

    ${breakpoint.smallAndDown} {
      padding: 0.3rem 1rem;
      line-height: 19px;
    }
  `,
  FormSubmit: styled(Form.Submit)`
    ${mixin.flexCenterBoth}
    height: 100%;

    ${breakpoint.smallAndDown} {
      padding: 0.3rem 1rem;
      line-height: 19px;
    }
  `,
  Dialog: styled(Dialog.Main)`
    max-width: 700px;
  `,
}

import { AddFriendButton } from 'src/components/Friends/AddFriendButton'
import { Avatar } from 'src/components/Display/Avatar'
import { breakpoint, button, mixin, copy } from 'src/styles'
import { FriendsButtonAndModal } from 'src/components/Friends/FriendsButtonAndModal'
import { getMemberDisplayName, formattedSentenceCase } from 'src/utils/formattingUtils'
import { Home } from 'src/components/Display/Home'
import { Icon } from 'src/components/Icon'
import { IncomingInvitationButtons } from 'src/components/Friends/IncomingInvitationButtons'
import { Link } from 'src/components/Link'
import { Location } from 'src/components/Display/Location'
import moment from 'moment'
import { RichText } from 'src/components/Display/RichText'
import { Username } from 'src/components/Display/Username'
import { useMember } from 'src/providers/MemberProvider'
import { useRouter } from 'src/hooks/useRouter'

const FriendingButton = () => {
  const router = useRouter()
  const { currentMember, canUseSocialFeatures, isFriend } = useMember()
  const { account, incomingInvitationDto, outgoingInvitationDto } = currentMember || {}

  if (isFriend) {
    return (
      <FriendsButtonAndModal
        refreshFunction={() => window.location.reload(true)}
        styles={FriendsButtonAndModalStyles}
      />
    )
  }

  if (incomingInvitationDto) {
    return (
      <Render.IncomingInvitationButtons
        invitation={incomingInvitationDto}
        redirectOnAccept={() => window.location.reload(true)}
      />
    )
  }

  if (outgoingInvitationDto) {
    return (
      <Render.AddFriendButton
        userId={account?.userId}
        isAlreadyAdded={true}
        existingFriendRequestId={outgoingInvitationDto?.id}
      />
    )
  }

  return (
    <Render.AddFriendButton
      userId={account?.userId}
      refreshFunction={!canUseSocialFeatures ? () => router.push('/profile/edit') : undefined}
    />
  )
}

export const ProfileCard = ({ className }) => {
  const { currentMember, isSelf } = useMember()

  const { avatarUrl, facebookUsername, firstName, instagramHandle, lastName, tagline, username } =
    currentMember?.profile || {}

  const { friendCount } = currentMember?.membershipActivity || {}
  const { city, state } = currentMember?.addressAttributes || {}
  const { foundingMember, memberSinceDate, annual, quarterly } = currentMember?.account || {}

  const formattedFriendCount =
    friendCount > 1000
      ? `${Math.floor((friendCount / 1000) * 10) / 10}K Friends`
      : `${friendCount} ${friendCount > 1 ? 'Friends' : 'Friend'}`

  return (
    <Render.Container className={className}>
      <Render.AvatarContainer>
        <Avatar
          avatarUrl={avatarUrl}
          firstName={firstName}
          foundingMember={foundingMember}
          isLevelUp={annual || quarterly}
          lastName={lastName}
        />
      </Render.AvatarContainer>
      <Render.DetailsContainer>
        <Render.Name>
          {getMemberDisplayName(currentMember, { useFullNameWithInitial: true })}
        </Render.Name>
        {username && <Render.Username username={username} />}
        {tagline && <Render.Tagline>{tagline}</Render.Tagline>}
        <Render.Details center>
          {(friendCount > 0 || (city && state)) && (
            <Render.DetailsTopRow>
              {friendCount > 0 && (
                <div>
                  <Render.Friends /> {formattedFriendCount}
                </div>
              )}
              {city && state && (
                <div>
                  <Render.Location /> {formattedSentenceCase(city)}, {state}
                </div>
              )}
            </Render.DetailsTopRow>
          )}
          <Render.DetailsBottomRow>
            <div style={{ marginRight: '10px' }}>
              <Render.Home /> Since {moment(memberSinceDate).format('MMMM YYYY')}
            </div>
            <Render.SocialContainer>
              {instagramHandle && (
                <Link to={`https://instagram.com/${instagramHandle}`}>
                  <Render.InstagramIcon hasFacebookIcon={facebookUsername} />
                </Link>
              )}
              {facebookUsername && (
                <Link to={`https://facebook.com/${facebookUsername}`}>
                  <Render.FacebookIcon />
                </Link>
              )}
            </Render.SocialContainer>
          </Render.DetailsBottomRow>
        </Render.Details>
      </Render.DetailsContainer>
      {isSelf ? (
        <Render.Button to={`/${currentMember?.profile?.username || 'profile'}/edit`}>
          Edit Profile
        </Render.Button>
      ) : (
        <FriendingButton />
      )}
    </Render.Container>
  )
}

export default ProfileCard

const Render = {
  Container: styled.div`
    ${mixin.flexCenterVertical}
    ${mixin.cardBoxShadow}
    width: 100%;
    background-color: #fff;
    margin: 24px 0;

    ${breakpoint.mediumAndDown} {
      flex-direction: column;
      padding: 24px;
    }

    ${breakpoint.largeAndUp} {
      justify-content: space-between;
      min-height: 200px;
    }
  `,
  AvatarContainer: styled.div`
    margin: auto 40px;

    ${breakpoint.mediumAndDown} {
      margin-bottom: 24px;
    }
  `,
  DetailsContainer: styled.div`
    ${mixin.flexCenterVertical}
    ${mixin.spaceChildrenVertical('16px')}
    flex-direction: column;

    ${breakpoint.largeAndUp} {
      margin-left: 42px;
    }
  `,
  Name: styled.div`
    ${copy.headerXlarge}
    font-weight: 200;
    text-align: center;
    margin-left: 8px;
  `,
  Username: styled(Username)`
    margin-top: -13px;
    font-size: 16px;
  `,
  Tagline: styled.div`
    font-size: 18px;
    text-align: center;

    ${breakpoint.mediumAndDown} {
      line-height: 22px;
    }
  `,
  Details: styled(RichText.Medium)`
    display: flex;
    line-height: 17px;

    > div {
      ${mixin.flexCenterBoth}
    }

    ${breakpoint.smallAndDown} {
      flex-direction: column;
    }

    ${breakpoint.mediumAndDown} {
      justify-content: center;
      margin-bottom: 24px;

      > div {
        min-width: fit-content;
      }
    }
  `,
  DetailsTopRow: styled.div`
    ${mixin.spaceChildrenHorizontal('18px')}
    >div {
      ${mixin.flexCenterVertical}
    }

    ${breakpoint.smallAndDown} {
      margin-bottom: 8px;
      margin-right: -13px;
      margin-left: -13px;
    }

    ${breakpoint.mediumAndUp} {
      margin-right: 18px;
    }
  `,
  DetailsBottomRow: styled.div`
    ${mixin.spaceChildrenHorizontal('18px')}
    >div {
      ${mixin.flexCenterVertical}

      ${breakpoint.smallAndDown} {
        margin-right: 20px !important;
      }
    }
  `,
  Friends: styled(Icon.Friends)`
    height: 14px;
    margin-right: 5px;
    margin-bottom: 1px;
  `,
  Location: styled(Location)`
    height: 14px;
    margin-right: 4px;
  `,
  Home: styled(Home)`
    width: 12px;
    height: 14px;
    margin-right: 4px;
    margin-bottom: 1px;
  `,
  Button: styled(Link)`
    ${button.secondary}
    margin: auto 40px;
    padding: 8px 0;
    width: 170px;
  `,
  IncomingInvitationButtons: styled(IncomingInvitationButtons)`
    margin: auto 40px auto 75px;
    padding: 8px 0;
    width: 120px;

    ${breakpoint.mediumAndDown} {
      margin: auto 40px;
    }
  `,
  AddFriendButton: styled(AddFriendButton)`
    margin: auto 40px auto 75px;
    padding: 8px 0;
    width: 120px;

    ${breakpoint.mediumAndDown} {
      margin: auto 40px;
    }
  `,
  SocialContainer: styled.span`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  `,
  FacebookIcon: styled(Icon.Facebook)`
    svg {
      width: 6px;
      height: 12px;
    }
  `,
  InstagramIcon: styled(Icon.Instagram)`
    svg {
      width: 14px;
      height: 14px;
    }

    path {
      fill: #000;
    }

    ${({ hasFacebookIcon }) =>
      hasFacebookIcon &&
      css`
        ${breakpoint.smallAndDown} {
          margin-right: 20px;
          width: unset !important;
        }
      `}
  `,
}

const FriendsButtonAndModalStyles = {
  ButtonsContainer: styled.div`
    position: relative;
    width: 130px;

    ${breakpoint.mediumAndDown} {
      margin: auto 40px;
    }

    ${breakpoint.largeAndUp} {
      margin: auto 40px auto 75px;
    }
  `,
}

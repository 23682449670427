import { breakpoint } from 'src/styles'
import { Link } from 'src/components/Link'
import useWindowScrollPosition from '@rehooks/window-scroll-position'
import { useUser } from 'src/hooks/useUser'
import { useRouter } from 'src/hooks/useRouter'

export const Tabs = ({ pathname, scrollOffset = 0, set }) => {
  const { y: scrollTop } = useWindowScrollPosition()
  const { isMember } = useUser()
  const router = useRouter()

  const [bannerHeight, setBannerHeight] = React.useState(0)

  const privateBanner = document.getElementsByClassName('marketing-promo-banner')[0]

  const isRecommendationsBannerVisible = isMember && router?.pathname === '/videos'

  const containerTop = `calc(var(--site-header-initial-height) + ${
    isRecommendationsBannerVisible ? 'var(--recs-banner-mobile-height)' : '0px'
  } + ${bannerHeight}px + ${scrollTop < scrollOffset ? scrollOffset : 0}px)`

  React.useEffect(() => {
    if (privateBanner) {
      const bannerHeight = privateBanner?.clientHeight
      setBannerHeight(bannerHeight)
      setTimeout(() => privateBanner?.clientHeight, 100)
    }
  }, [privateBanner?.clientHeight])

  return (
    <Styles.Container
      style={{
        position: scrollTop < scrollOffset ? 'absolute' : 'fixed',
        top: containerTop,
      }}
    >
      {set.map(({ path, label }, index) =>
        React.createElement(
          Styles.Item,
          {
            isActiveTab: path === pathname,
            key: `${index}${path}`,
            onClick: () => window.scrollTo(0, 0),
            to: path,
          },
          label
        )
      )}
    </Styles.Container>
  )
}

const Styles = {
  Container: styled.div`
    display: none;
    height: var(--sub-header-mobile-height);
    ${breakpoint.mediumAndDown} {
      border-top: 1px solid #ddd;
      display: flex;
      left: 0;
      width: 100%;
      z-index: 100;
    }
  `,
  Item: styled(Link)`
    background: white;
    cursor: pointer;
    flex-basis: 50%;
    font-size: 12px;
    letter-spacing: 0.58px;
    line-height: 44px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;

    ${({ isActiveTab }) =>
      isActiveTab &&
      css`
        background: var(--yellow);
        cursor: default;
      `}

    :hover {
      text-decoration: none;
    }

    :focus {
      outline: 0;
      text-decoration: underline;
    }
  `,
}

import { breakpoint } from 'src/styles'
import { Checkmark } from 'src/components/Icon/Checkmark'
import { Dismiss } from 'src/components/Display/Dismiss'
import { Icon } from 'src/components/Icon'
import { UserAction } from 'src/components/UserAction'

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string
  error?: string
  hint?: string
  label?: string
  onSubmit?: () => void
  prefix?: string
  type?: string
  value?: string
  usernameIsAvailable?: boolean
  countryCode?: string
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, error, hint, label, onSubmit, prefix, type = 'text', ...props }, forwardedRef) => {
    const componentRef = React.useRef(null)
    const ref = forwardedRef || componentRef

    const [focused, setFocused] = React.useState(false)
    const [isInputVisible, setInputVisible] = React.useState(type !== 'password')

    const animated =
      focused ||
      !!(ref as React.MutableRefObject<HTMLInputElement>)?.current?.value ||
      !!props.value
    const id = `input-main-${Math.random()}`

    const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
      if (e.key === 'Enter' && typeof onSubmit === 'function') onSubmit()
    }

    const inputType = (() => {
      if (isInputVisible && type === 'password') return 'text'

      return type
    })()

    const passwordLengthError =
      (props?.value?.length || 0) > 0 &&
      (props?.value?.length || 0) < 6 &&
      type === 'password' &&
      !focused

    return (
      <Styles.Container className={className} hasError={!!error || passwordLengthError}>
        <div style={{ position: 'relative' }}>
          {prefix && animated && <Styles.Prefix>{prefix}</Styles.Prefix>}
          {label && (
            <Styles.Label htmlFor={id} animated={animated} countryCode={props.countryCode}>
              {label}
            </Styles.Label>
          )}
          <div
            style={{
              display: 'flex',
            }}
          >
            <Styles.Input
              id={id}
              animated={animated}
              hasError={!!error || passwordLengthError}
              hasPrefix={!!prefix}
              onBlur={() => setFocused(false)}
              onFocus={() => setFocused(true)}
              onKeyDown={handleKeyDown}
              ref={ref}
              type={inputType}
              {...props}
            />
            {props?.usernameIsAvailable !== undefined && (
              <Styles.IsAvailable>
                {props?.usernameIsAvailable ? <Styles.Checkmark /> : <Styles.Dismiss redStyling />}
              </Styles.IsAvailable>
            )}
          </div>
          {type === 'password' && (
            <Styles.Eye onClick={() => setInputVisible(!isInputVisible)}>
              {isInputVisible ? <Icon.Eye /> : <Icon.EyeClosed />}
            </Styles.Eye>
          )}
        </div>
        {hint && !error && type !== 'password' && <Styles.Hint>{hint}</Styles.Hint>}
        {passwordLengthError && !error && <Styles.Error>{hint}</Styles.Error>}
        {error && <Styles.Error>{error}</Styles.Error>}
      </Styles.Container>
    )
  }
)

const Styles = {
  Container: styled.div<{ hasError: boolean }>`
    min-width: 100px;
    text-align: left;
    border-radius: 0px;

    ${breakpoint.mediumAndUp} {
      ${({ hasError }) =>
        hasError &&
        css`
          margin-bottom: 8px !important;
        `}
    }

    ${breakpoint.smallAndDown} {
      ${({ hasError }) =>
        hasError &&
        css`
          margin-bottom: 8px !important;
        `}
    }
  `,
  Prefix: styled.span`
    position: absolute;
    left: 10px;
    color: var(--mediumGrey);
    top: 43%;
    z-index: 1;
  `,
  Label: styled.label<{ countryCode?: string; animated: boolean }>`
    pointer-events: none;
    color: var(--mediumGrey);
    position: absolute;
    left: 11px;
    z-index: 1;
    font-size: 16px;
    margin: 0;

    ::before {
      content: '${(props) => props.countryCode}';
      color: var(--neutrals800);
      font-size: 16px;
      top: 14.7px;
      position: absolute;
    }

    transition: all 150ms;
    top: 50%;
    transform: translateY(-50%);

    ${({ animated, countryCode }) =>
      (animated || countryCode) &&
      css`
        top: 10px;
        transform: unset;
        font-size: 12px;
      `}
  `,
  Input: styled.input<{ animated: boolean; hasPrefix: boolean; hasError: boolean }>`
    color: var(--neutrals800);
    width: 100%;
    height: 60px;
    padding: 29px 11px 12px 11px;
    background-color: white;
    outline: none;
    flex: 1;
    position: relative;

    :disabled {
      background: var(--neutrals400);
      border: 2px solid var(--neutrals500);
      border-radius: 8px;
    }

    border: 1px solid var(--neutrals600);
    border-radius: 8px;

    :focus {
      border: 2px solid var(--black);
      border-radius: 8px;
    }

    ::placeholder {
      color: var(--mediumGrey);
      transition: color 200ms;

      ${({ animated }) =>
        !animated &&
        css`
          color: transparent;
        `}
    }

    ${({ hasPrefix }) =>
      hasPrefix &&
      css`
        padding-left: 30px;
      `}

    ${({ hasError }) =>
      hasError &&
      css`
        border-color: #ff0000 !important;
      `}

      ${({ hasError }) =>
      hasError &&
      css`
        border: 2px solid var(--feedback200);
      `}
  `,
  Hint: styled.label`
    margin-top: 6px;
    margin-bottom: 0px;
    font-size: 12px;
    color: var(--mediumGrey);
  `,
  Error: styled.label`
    margin-top: 8px;
    margin-bottom: 0px;
    font-size: 12px;
    color: #ff0000;
    font-weight: 400;
  `,
  IsAvailable: styled.div`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 12px;
  `,
  Checkmark: styled(Checkmark)`
    width: 14px;

    svg {
      fill: #37a801;
      stroke: #37a801;
    }
  `,
  Dismiss: styled(Dismiss)`
    width: 12px;
    height: 12px;
    fill: #ff0000;
    stroke: #ff0000;
  `,
  Eye: styled(UserAction)`
    position: absolute;
    right: 16px;
    top: 30px;
  `,
}
